<template>
  <div class="filter-search-container relative px-1 transition-colors ease-in-out"
  :style="{maxWidth: searchWidth, marginRight: !isSubType && !isExpanded ? '52px' : '0px' }"
  :class="isExpanded ? 'expanded rounded-t-lg' : 'rounded-lg'" 
  v-on-clickaway="() => { if (!isSubType) isExpanded = false}">
    <div id="filterSearch" class="flex items-center gap-2 w-full px-2 py-1" @click="barClicked"
    :class="{'cursor-pointer z-50': !isExpanded}">
      <svg v-show="isExpanded" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="flex-shrink-0">
        <path d="M16.875 16.875L13.4388 13.4388M13.4388 13.4388C14.5321 12.3454 15.2083 10.835 15.2083 9.16667C15.2083 5.82995 12.5034 3.125 9.16667 3.125C5.82995 3.125 3.125 5.82995 3.125 9.16667C3.125 12.5034 5.82995 15.2083 9.16667 15.2083C10.835 15.2083 12.3454 14.5321 13.4388 13.4388Z"
        stroke="#5E6678" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <!-- <img v-show="isExpanded" src="../../../assets/icons/search.svg" alt="" class="w-5 h-5" /> -->
      <div v-show="!isExpanded" class="w-5">
        <FilterIcon stroke="#808899" />
      </div>
      <input :ref="`filter-${filterType}-input`" v-model="searchQuery" :placeholder="placeholder"
      class="filter-search-input flex-grow min-w-0 bg-transparent" :class="{'cursor-pointer': !isExpanded}"
      autocomplete="off" type="text" spellcheck="false"
      @focus="() => { isExpanded = true; keyboardIndex = -1; $emit('focusCurr') }"
      @keydown.down.prevent="highlightNext"
      @keydown.up.prevent="highlightPrev"
      @keydown.enter.prevent="selectHighlighted"
      @keydown.right="focusNext"
      @keydown.left="focusPrev"
      @keydown.escape.prevent="collapseSearch"
      @keydown="($event) => { if ($event.key === 'Delete') { $event.preventDefault(); $emit('removeFilterType') }}"/>
    </div>
    <div v-if="isExpanded" :class="{'expanded' : isExpanded}"
    class="filter-search-dropdown-container absolute top-full left-0 right-0 overflow-hidden rounded-b-lg bg-white">
      <div class="filter-search-dropdown flex flex-col gap-0.5" :class="[{'expanded' : isExpanded}, filterType === 'contentFilter' ? 'pb-1' : 'py-1']">
        <!-- Beta Label -->
        <div v-if="filterType === 'contentFilter'" class="flex justify-start items-center gap-2 bg-neutral-25 py-1.5 px-3">
          <SolidImageIcon />
          <BaseText type="label" size="xs" class="text-text-muted">
            Beta
          </BaseText>
          <BaseText type="body" size="xs" class="text-text-muted">
            Image Ads Only
          </BaseText>
        </div>
        <div v-for="(item, index) in searchResults" :key="index" @click.stop="itemClicked(item)" @mouseenter="keyboardIndex = -1"
        class="flex items-center gap-2 px-2 mx-1 py-1.5 rounded-md transition-colors ease-in-out hover:bg-neutral-25 hover:z-50 cursor-pointer"
        :class="{'bg-neutral-25 z-50': keyboardIndex === index}" :ref="`${filterType}-item-${index}`">
          <!-- Icon rendering -->
          <TagIcon v-if="filterType === 'tag'" :stroke="item.color ? tagColors[item.color].stroke : tagColors['gray'].stroke"
          :fill="item.color ? tagColors[item.color].fill : tagColors['gray'].fill" class="flex-shrink-0" />
          <img v-else-if="filterType === 'brand'" :src="item.icon" class="w-5 h-5 object-fill flex-shrink-0" style="border-radius: 4.5px" />
          <div v-else-if="filterType === 'status'" class="px-1.5 flex-shrink-0">
            <div class="w-1.5 h-1.5 rounded-full" :style="{backgroundColor: item.value === 'live' ? '#00A879' : '#9DA3AE'}" />
          </div>
          <component v-else-if="filterType === 'platform'" :is="item.icon" class="flex-shrink-0 w-5 h-5 p-0.5" />
          <component v-else :is="item.icon" :width="20" :height="20" stroke="#5E6678" class="flex-shrink-0 text-icon-normal" />
          <!-- Item name -->
          <BaseText type="body" size="sm" class="w-full text-text-normal truncate">
            {{ item.name }}
          </BaseText>
          <!-- "New" label for content filters and market targets -->
          <div v-if="!isSubType && ['Content Style', 'Target Market'].includes(item.name)" class="px-1.5 py-0.5 rounded-full" :class="`bg-primary-${getTheme}-10`">
            <BaseText type="label" size="xs" :class="`text-primary-${getTheme}-300`">New</BaseText>
          </div>
          <!-- Checkmark if selected -->
          <div v-if="isSubType && isItemSelected(item)" class="ml-auto flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M5.375 11.5469L8.5 14.125L14.625 5.875" stroke="#5E6678" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <!-- Inform user that DCO is meta only -->
          <div v-if="filterType === 'format' && item.name === 'DCO' && !isItemSelected(item)" 
          class="flex items-center gap-1 px-1.5 py-0.5 rounded-full bg-primary-blue-10">
            <MetaPlatformIcon />
            <BaseText type="label" size="xs" class="text-primary-blue-300">
              Meta Only
            </BaseText>
          </div>
        </div>
        <div v-if="searchResults.length === 0" class="px-2 mx-1 py-1.5 text-sm text-text-disabled">
          {{ isSubType || searchQuery.length > 0 ? 'No results found' : 'All filters applied' }}
        </div>
        <!-- Inform user that live status only works for FB ad library -->
        <div v-if="filterType === 'status'" 
        class="flex gap-2 items-center px-3 pt-2 mt-0.5 pb-1 border-t border-border-normal cursor-default">
          <FacebookPlatformIcon />
          <div class="text-sm text-text-normal">
            Ad Library Ads Only
          </div>
        </div>
      </div>
      <div v-if="searchResults.length > 8" class="dropdown-fade-overlay pointer-events-none" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import FilterIcon from '../../globals/Icons/FilterIcons/FilterIcon.vue'
import TagIcon from '../../globals/Icons/TagIcon.vue'
import SolidImageIcon from '../../globals/Icons/FilterIcons/SolidImageIcon.vue'
import FacebookPlatformIcon from '../../globals/Icons/FilterIcons/Platforms/FacebookPlatformIcon.vue'
import MetaPlatformIcon from '../../globals/Icons/FilterIcons/Platforms/MetaPlatformIcon.vue'
import TAG_COLORS from '../../../utils/TagColors'

export default {
  name: 'FilterSearch',
  components: {
    FacebookPlatformIcon,
    FilterIcon,
    SolidImageIcon,
    MetaPlatformIcon,
    TagIcon
  },
  mixins: [clickaway],
  props: {
    filterType: {
      type: String,
      default: ''
    },
    isSubType: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Add a Filter'
    },
    selections: {
      type: Array,
      default: () => []
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    hasNextItem: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchQuery: '',
      isExpanded: false,
      keyboardIndex: -1,
      tagColors: TAG_COLORS
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme']),
    searchResults () {
      let searchItems = this.items.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()))

      // Sort by the closeness of the match (closer = lower index)
      searchItems.sort((a, b) => {
        const ratioA = this.searchQuery.length / a.name.length;
        const ratioB = this.searchQuery.length / b.name.length;

        return ratioB - ratioA;
      });

      return searchItems;
    },
    searchWidth () {
      if (this.filterType === 'contentFilter') return '220px'
      if (this.isSubType) return '200px'
      else {
        return this.isExpanded ? '191px' : '139px'
      }
    }
  },
  watch: {
    isFocused (newVal) {
      if (!this.isSubType && newVal) {
        this.isExpanded = true
        this.$refs[`filter-${this.filterType}-input`].focus()
      }
    }
  },
  mounted () {
    if (this.isSubType) {
      this.$refs[`filter-${this.filterType}-input`].focus()
      this.isExpanded = true
    }
  },
  methods: {
    itemClicked (item, wasKeypress = false) {
      item.action()
      if (!this.isSubType) this.isExpanded = false

      // Since status only has two options, we close the dropdown on selection
      else if (this.filterType === 'status') {
        if (wasKeypress) this.$emit('focusPrev')
        else this.$emit('collapseSearch')
      }
    },
    isItemSelected (item) {
      return this.selections.some(selection => selection.name === item.name)
    },
    barClicked () {
      if (!this.isSubType) this.$refs[`filter-${this.filterType}-input`].focus()
    },

    // ========== KEYBOARD CONTROLS ==========
    highlightNext () {
      if (this.keyboardIndex < this.searchResults.length - 1) {
        this.keyboardIndex++
        this.scrollToItem(`${this.filterType}-item-${this.keyboardIndex}`)
      }
    },
    highlightPrev () {
      if (this.keyboardIndex > -1) {
        this.keyboardIndex--
        if (this.keyboardIndex !== -1) this.scrollToItem(`${this.filterType}-item-${this.keyboardIndex}`)
      }
    },
    selectHighlighted () {
      if (this.keyboardIndex > -1) {
        this.itemClicked(this.searchResults[this.keyboardIndex], true)
      }
    },
    scrollToItem (ref) {
      this.$nextTick(() => {
        const elm = this.$refs[ref][0]
        if (elm) elm.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      })
    },
    focusNext () {
      if (this.searchQuery === '' && this.hasNextItem) {
        this.$refs[`filter-${this.filterType}-input`].blur()
        this.$emit('focusNext')
        if (!this.isSubType) this.isExpanded = false
      }
    },
    focusPrev () {
      if (this.searchQuery === '') {
        this.$emit('focusPrev')
        if (this.isSubType) this.$refs[`filter-${this.filterType}-input`].blur()
      }
    },
    collapseSearch () {
      if (!this.isSubType) {
        this.isExpanded = false
        this.$refs[`filter-${this.filterType}-input`].blur()
      } 
      else this.$emit('collapseSearch')
    }
  }
}
</script>

<style scoped>
.filter-search-container {
  transition: box-shadow 150ms ease-in-out, padding-top 100ms ease-in-out, padding-bottom 100ms ease-in-out, background-color 150ms ease-in-out;
  box-shadow: none;
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: transparent;
}
.filter-search-container:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px #FFF;
  background-color: white;
}
.filter-search-container.expanded {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px #FFF;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background-color: white;
}
.filter-search-dropdown-container {
  opacity: 0;
  z-index: 100;
}
.filter-search-dropdown-container.expanded {
  opacity: 1;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08), 0px 0px 0px 1px #FFF;
}
.filter-search-dropdown {
  overflow-y: scroll;
  max-height: 0px;
  scrollbar-width: none;
}
.filter-search-dropdown.expanded {
  transition: max-height 150ms ease-out;
  max-height: 362px;
}
.dropdown-fade-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 16px;
  z-index: 40;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffffb9 100%);
}
.filter-search-input {
  /* Reset default styles */
  -webkit-appearance: none;  /* Remove default styling in WebKit browsers */
  -moz-appearance: none;     /* Remove default styling in Firefox */
  appearance: none;          /* Remove default styling in modern browsers */
  padding: 0;
  margin: 0;
  border: none;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  outline: none;
}
.filter-search-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.filter-search-input::placeholder {
  transition: color 150ms ease-in-out;
  color: #5E6678;
}
.filter-search-input:hover::placeholder {
  color: black;
}
.filter-search-input:focus::placeholder {
  color: #A4ACB9;
}
</style>
